export const SYLABY = [
    "ka",
    "na",
    "ko",
    "ma",
    "ta",
    "la",
    "ra",
    "ne",
    "ni",
    "si",
    "wa",
    "ci",
    "mi",
    "za",
    "ro",
    "po",
    "ki",
    "le",
    "no",
    "wi",
    "zi",
    "lu",
    "lo",
    "pe",
    "pa",
    "sa",
    "se",
    "so",
    "su",
    "mu",
    "ku",
    "ke",
    "mo",
    "me",
    "pi",
    "ti",
    "te",
    "to",
    "tu",
    "ja",
    "je",
    "ju",
    "jo",
    "cho",
    "cha",
    "che",
    "chi",
    "re",
    "ri",
    "ru",
    "ba",
    "be",
    "bi",
    "bo",
    "bu",
    "da",
    "de",
    "di",
    "do",
    "du",
    "fa",
    "fe",
    "fi",
    "fo",
    "fu",
    "ga",
    "ge",
    "gi",
    "go",
    "gu",
    "ha",
    "he",
    "hi",
    "ho",
    "hu",
    "je",
    "łe",
    "li",
    "me",
    "mi",
    "my",
    "ne",
    "ni",
    "no",
    "nu",
    "re",
    "ro",
    "ru",
    "se",
    "si",
    "so",
    "su",
    "śle",
    "śli",
    "ta",
    "te",
    "ti",
    "to",
    "tu",
    "wa",
    "we",
    "wi",
    "wo",
    "wu",
    "by",
    "ma",
    "bie",
    "cio",
    "cie",
    "ra",
    "ry",
    "ca",
    "ce",
    "co",
    "cu",
    "dy",
    "dź",
    "dż",
    "dzi",
    "dze",
    "fo",
    "fie",
    "fio",
    "gle",
    "glo",
    "gła",
    "kra",
    "kre",
    "kro",
    "kru",
    "le",
    "la",
    "łka",
    "ła",
    "ło",
    "ły",
    "mo",
    "ma",
    "mi",
    "my",
    "na",
    "ne",
    "ni",
    "no",
    "nu",
    "pa",
    "pe",
    "pi",
    "po",
    "pu",
    "re",
    "ra",
    "ri",
    "ro",
    "ru",
    "sa",
    "se",
    "si",
    "so",
    "su",
    "śmi",
    "śle",
    "śla",
    "ślu",
    "śli",
    "ta",
    "te",
    "ti",
    "to",
    "tu",
    "wo",
    "wa",
    "wi",
    "we",
    "ze",
    "za",
    "zi",
    "zo",
    "zu",
    "ża",
    "że",
    "żi",
    "żo",
    "żu",
];
