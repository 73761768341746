import React, { useState } from "react";
import { SYLABY } from "./constants";

var index = 0;
var voices = [];
var paulinaVoice = undefined;

function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

function setVoice() {
    delay(1000).then(() => {
        if (voices.length == 0) {
            voices = window.speechSynthesis.getVoices();
            paulinaVoice = voices.find((voice) => voice.name === "Google polski" && voice.lang === "pl-PL");
            console.log(paulinaVoice);
        }

        if (voices.length == 0) {
            setVoice();
        }
    });
}

setVoice();

function App() {
    const [sylaba, setSylaba] = useState(SYLABY[index]);
    const [size, setSize] = useState(100);

    function nextClickHandler() {
        console.log(index);
        if (index < SYLABY.length - 1) {
            index++;
            setSylaba(SYLABY[index]);
            delay(1000).then(() => play(SYLABY[index]));
        }
    }

    function previousClickHandler() {
        console.log(index);
        if (index > 0) {
            index--;
            setSylaba(SYLABY[index]);
            delay(1000).then(() => play(SYLABY[index]));
        }
    }

    function increaseClickHandler() {
        console.log(size);
        if (size < 300) {
            setSize(size + 10);
            play();
        }
    }

    function decreaseClickHandler() {
        console.log(size);
        if (size > 100) {
            setSize(size - 10);
            delay(1000).then(() => play);
        }
    }

    function play(text) {
        if (!(typeof text === "string" || text instanceof String)) {
            text = sylaba;
        }
        // var paulinaVoice = voices.filter((voice) => voice.lang.startsWith("pl"));
        // console.log(paulinaVoice);
        const speech = new SpeechSynthesisUtterance(text); // Create a speech synthesis utterance
        // speech.lang = "pl-PL"; // Set the language of the speech
        // speech.rate = 1; // Set the speed of the speech
        // speech.pitch = 1; // Set the pitch of the speech
        speech.voice = paulinaVoice; // Set the voice of the speech

        window.speechSynthesis.speak(speech); // Speak the text
    }

    return (
        <div className="container-fluid d-flex flex-column justify-content-between" style={{ height: "100vh" }}>
            <div className="row flex-grow-1">
                <div className="col d-flex flex-column justify-content-center align-items-center">
                    <div style={{ fontSize: size + "px" }}>{sylaba}</div>
                    <button type="button" className="btn btn-info mt-3" onClick={play}>
                        Odtwórz
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex justify-content-center pb-3">
                    <button type="button" className="btn btn-primary btn-lg mx-2" onClick={previousClickHandler}>
                        Poprzednia
                    </button>
                    <button type="button" className="btn btn-primary btn-lg mx-2" onClick={nextClickHandler}>
                        Następna
                    </button>
                </div>
            </div>
            <div
                style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <button type="button" className="btn btn-danger mb-2" onClick={increaseClickHandler}>
                    Wieksze
                </button>
                <button type="button" className="btn btn-danger" onClick={decreaseClickHandler}>
                    Mniejsze
                </button>
            </div>
        </div>
    );
}

export default App;
